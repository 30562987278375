body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
    overflow-y: hidden; /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
  }
.dx-drawer {
  height: calc(100vh - 76px) !important;
}
/*!* Hide scrollbar for Chrome, Safari and Opera *!*/
/*::-webkit-scrollbar {*/
/*  display: none;*/
/*}*/
/*!* Hide scrollbar for IE, Edge and Firefox *!*/
/*body {*/
/*  -ms-overflow-style: none;  !* IE and Edge *!*/
/*  scrollbar-width: none;  !* Firefox *!*/
/*}*/
