

.homePage3 {

  #gallery {
    margin: auto;
    max-width: 1920px;
    left: 0;
    right: 0;
    text-shadow: 1px 1px 2px rgb(0 0 0 / 50%);
  }
  .index-business--content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    z-index: 2;
    text-align: left;
    width: 100%;
    white-space: normal;
  }

  .index-business--img {
    height: 500px;
    position: absolute;
    background-repeat: no-repeat;
    z-index: 1;
    background: center;
    filter: brightness(70%);
  }
  #gallery:hover .index-business--img {
     transform: scale(1.10);
     opacity: 0.7;
     transition: opacity 0.4s, transform 0.4s;
   }
  #gallery:not(:hover) .index-business--img {
    transform: scale(1);
    opacity: 1;
    transition: opacity 0.4s, transform 0.4s;
  }
  .section-title {
    font-size: 40px;
    line-height: 58px;
    font-weight: 800;
    margin: 0 0 40px;
  }

  .index-business--text {
    font-size: 20px;
    line-height: 30px;
    padding-left: 32px;
    border-left: 2px solid #33a4bc;
    margin-bottom: 50px;
  }
  .app, .container, .container-fluid, .main-background-fon-color {
    background-color: #00000000;
  }
  .dark-background{background-color: #00000011;}

  .dx-card {
    box-shadow: none;
    background-color: transparent;
  }
  .text-responsive {
    font-size: calc(100% + 2vw + 1vh)!important;
    line-height: calc(100% + 1vw + 1vh)!important;
  }
  .dx-tile-image {
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    display: block;
  }
  .dx-tile-image:hover {
    transform: scale(1.10);
    opacity: 0.7;
    transition: opacity 0.4s, transform 0.4s;
  }
  .dx-tile-image:not(:hover) {
     transform: scale(1);
     opacity: 1;
     transition: opacity 0.4s, transform 0.4s;
   }

  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700;900&display=swap');

  //body {
  //  font-family: Roboto, sans-serif;
  //  margin: 0;
  //  height: 400vh;
  //}

  //.block {
  //  margin-top: 50vh;
  //}

  .block__svg {
    display: block;
  }

  .svg__mask-line {
    font-weight: 900;
    font-size: 800px;
  }

  .svg__first-line {
    font-weight: 700;
    font-size: 80px;
    text-transform: uppercase;
  }

  .svg__second-line {
    font-weight: 700;
    font-size: 360px;
    text-transform: uppercase;
  }
  h2 {
    //text-shadow: 1px 1px 2px rgb(0 0 0 / 50%);
    //font-size: calc(50% + 2vw + 1vh) !important;
    //line-height: calc(50% + 1vw + 1vh) !important;
    //font-size: 64px!important;
    margin-top: 20px!important;
    margin-bottom: 20px!important;
    //font-weight: 900!important;
  }

}


