.dx-theme-material-typography {
    .dx-item .dx-treeview-item {
        margin-bottom: -10px;
        margin-top: -10px;
    }
}


.dx-dropdowneditor-overlay:not(.dx-selectbox-popup-wrapper) > .dx-overlay-content {
    //max-height: initial !important; // 80%!important;//inherit!important;
}

