//.dx-dropdowneditor-button .dx-button-content {
//  text-align: center;
//}
//
//.dx-theme-material .dx-dropdowneditor-button .dx-button-content .dx-loadindicator {
//  width: 24px;
//  height: 24px;
//}

.custom-icon {
  max-height: 100%;
  max-width: 100%;
  font-size: 28px;
  display: inline-block;
  vertical-align: middle;
}

.dx-theme-android5 .custom-item img {
  padding: 4px 22px 0 0;
}

.custom-item img {
  float: right;
}

.custom-item .product-name {
  line-height: 32px;
  padding-left: 5px;
}


