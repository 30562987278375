//@use "~devextreme/scss/widgets/material/colors" with ($color: "teal", $mode: "light");
@import "../../themes/generated/variables.base.scss";
@import "../../dx-styles.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .informer {
    height: 70px;
    width: 110px;
    text-align: center;
  }

  .dx-toolbar .dx-toolbar-items-container,
  .dx-device-mobile .dx-toolbar .dx-toolbar-items-container {
    height: 76px;
  }

  .count {
    padding-top: 15px;
    line-height: 27px;
    margin: 0;
  }
  h4.count {
    width: 32px;
    margin: auto;
  }
  .badge {
    position: absolute;
    margin-top: -39px;
    margin-left: 15px;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    //padding-top: 6px;
    //font-size: 0.3em;
    padding-top: 5px;
    font-size: 10px;
  }
}

.select-dog-in-header{
input{font-size: 0.9rem;}
  .responsive-paddings {
    padding: 20px;

    .screen-large & {
      padding: 20px;
    }
  }
  .dx-card {
    overflow: hidden;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    margin: 0;
  }
  .screen-large &,.screen-small &, .screen-medium &, .screen-x-small &{
    .content-block {
      margin-top: 0;
    }
    .content-block {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.dx-toolbar-menu-section{
  .informer {
    height: 50px;
    width: 170px;
    text-align: left;
  }

  .count {
    padding-top: 15px;
    line-height: 27px;
    margin: 0;
  }
  h4.count {
    width: 32px;
    margin: auto;
    display: inline-block;
    margin-right: 12px;
    top: -5px;
    position: relative;
  }
  .badge {
    position: absolute;
    margin-top: -39px;
    margin-left: 24px;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    //padding-top: 6px;
    //font-size: 0.3em;
    padding-top: 5px;
    font-size: 10px;
  }
  span.name{
    top: -8px;
    position: relative;
  }
  .main-color-interact svg path{
    fill: #a3a3a7;
  }
  .main-color-interact{
    color:#000!important;
  }
  .main-color-interact:hover{
    color:#fff;
    svg path{
      fill: #fff!important;
    }
  }
  .theme-select{
    padding: 10px 20px 10px 20px;
  }
}




.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}

//.dx-theme-generic {
//  .dx-toolbar {
//    padding: 10px 0;
//  }
//
//  .user-button>.dx-button-content {
//    padding: 3px;
//  }
//}

//a.dog-href:hover{
//  color: #000; //TODO по идее из темы тянуть
//}
