.homemain {
  body {
    background: #fff;
  }

  #categories > .col-md-4.mb-5 {
    margin-bottom: 8rem !important;
  }

    .edge-header {
    display: block;
    height: 278px;
    background-color: #ccc;
  }


.mainsvgcolor a h4 {
  font-size: 1.6rem;
}


}

