//.energoobjects{}

// @media screen and (max-width: 900px) {
//     .energoobjects {

//     }
// }

.info-form {
    min-width: 300px;
    max-width: 800px;
}