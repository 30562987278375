.select-dogovor {
    .bd-container-sidebar {
        float: right;
        width: 3.5rem;
        margin-top: 5px;
    }
    .bd-container-body {
        margin-right: 4rem;
    }
}

.none {
    display: none;
}

.block {
    display: block;
}

