@import "./themes/generated/variables.base.scss";

.app {
  //background-color: darken($base-bg, 5.00);
  display: flex;
  height: 100%;
  width: 100%;
}

.app .content {
  line-height: 1.5;

  h2 {
    //font-size: 30px;
    //margin-top: 20px;
    //margin-bottom: 20px;
  }
}

.app .content-block {
  //margin-left: 40px;
  //margin-right: 40px;
  margin-top: 20px;
}

.screen-x-small .content-block {
  margin-left: 20px;
  margin-right: 20px;
}

.responsive-paddings {
  padding: 20px;

  .screen-large & {
    padding: 30px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.with-footer > .dx-scrollable-wrapper >
.dx-scrollable-container > .dx-scrollable-content {
  height: 100%;

  & > .dx-scrollview-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}



html, body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}
.w100{width: 100%}
.mt0{margin-top: 0}
.mt10{margin-top: 10px}
.mt20{margin-top: 20px}
.mt40{margin-top: 40px}
.mt60{margin-top: 60px}
.mt-10{margin-top: -10px}
.mb0{margin-bottom: 0}
.mb10{margin-bottom: 10px}
.mb20{margin-bottom: 20px}
.mb100{margin-bottom: 100px}
.m20{margin: 20px}
.p20{padding: 20px}
.pt60{padding-top: 60px}
.pb60{padding-bottom: 60px}
.ml10{margin-left: 10px}
.mr10{margin-right: 10px}
.ml20{margin-left: 20px}
.mr20{margin-right: 20px}
@media (max-width: 576px) {

    .mt-10sm{margin-top: -10px}
    .mt10sm{margin-top: 10px}
    .mt20xs{margin-top: 20px}

  .mainsvgcolor{
    .badge{
      left: 1%!important;
      margin-top: -3.8rem!important;
    }
  }
}
@media (max-width: 767px) {
.mt10md{margin-top: 10px}
  .mt20md{margin-top: 20px}
}

/*иконка в заголовке h*/
.icon-header-h3 {
  float: left;
  margin-right: 10px;
  font-size: 21px;
  padding: 2px 0 2px 0;
}
.dx-theme-material-typography .popup-title-with-icon{
  margin:20px 0;
}

/*Нормализация заголовков во всех material темах в соответствии
с generic темой иначе они гигантские*/

.dx-theme-material-typography .dx-font-xl,
.dx-theme-material-typography h1 {
  font-weight: 200;
  font-size: 34px;
}
.dx-theme-material-typography .dx-font-l,
.dx-theme-material-typography h2 {
  font-weight: 400;
  font-size: 28px;
}
.dx-theme-material-typography h3 {
  font-weight: 400;
  font-size: 22px;
}
.dx-theme-material-typography .dx-font-m {
  font-weight: 400;
  font-size: 20px;
}
.dx-theme-material-typography .dx-font-s,
.dx-theme-material-typography h4 {
  font-weight: 500;
  font-size: 18px;
}
.dx-theme-material-typography h5 {
  font-weight: 700;
  font-size: 16px;
}
.dx-theme-material-typography .dx-font-xs,
.dx-theme-material-typography h6,
.dx-theme-material-typography small {
  font-weight: 800;
  font-size: 12px;
}
