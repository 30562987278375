@import './../../themes/generated/variables.base.scss';

.form-avatar {
  float: left;
  height: 120px;
  width: 120px;
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  text-align: center;
  color: $base-bg;
  padding-top: 2%;
  font-size: 50px;

  img {
    height: 120px;
    display: block;
    margin: 0 auto;
  }
}

.policy-info {
  margin: 10px 0;
  color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  font-size: 14px;
  font-style: normal;
}
