.square {
    width: 10px;
    height: 10px;
    margin-right: 5px;
}
    .error {
        background-color: #dd3444;
    }
    .warning {
        background-color: orange;
    }
    .success {
        background-color: #5cb85b;
    }
.square-span {
    margin-right: 10px;
}